export default defineNuxtRouteMiddleware(async ({ name, params }) => {
  const localePath = useLocalePath()
  const locales = ['en', 'fr']
  const locale = name.substr(-2)

  if (!locales.includes(locale)) {
    return navigateTo(
      localePath('blog'),
      {
        redirectCode: 301,
      },
    )
  }

  const { data } = await useFetch(`/api/content/pages/blog-posts/${locale}`, {
    key: `middleware-blog-post-[slug]-${params.slug}-${locale}`,
    transform: (input) => {
      const page = input.find(({ slug }) => slug === params.slug)
      return {
        page,
      }
    },
  })

  if (!data.value.page) {
    return navigateTo(
      localePath('blog'),
      {
        redirectCode: 301,
      },
    )
  }
})
